import './Software.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BizOnline from '../pages/BizOnline';


let initialData = [
  { id: 0, title: 'Bring Your Business Online', date: 'September 2023', synopsis: 'Taking your small business online is a crucial step to expand your reach, connect with customers, and increase your revenue potential. Here is a step-by-step guide to help you get your business up and running on the internet', page: 'online'},
  { id: 1, title: 'Becoming an Azure DevOps Expert', date: 'October 2023', synopsis: 'Becoming an expert in Azure DevOps without prior experience can be achieved by leveraging transferable skills from related tools and practices. Here are some essential skills, along with examples of similar tools in the industry, that can help you excel in Azure DevOps', page: 'devops'},
  { id: 2, title: 'Azure DevOps Buzzwords', date: 'October 2023', synopsis: 'Becoming the most knowledgeable person on Azure DevOps is an ambitious goal, and it requires a deep understanding of various concepts and tools. Here is a list of key buzzwords and phrases, along with brief meanings, that are essential for someone aiming to be an expert in Azure DevOps', page: 'devops'},

]



function Blog() {
  const [apps, setApps] = useState(
    initialData
  );
  

  const alertLinks = (obj) => {
    // const redirUrl = 'https://www.google.com'
    // alert("hey!!! " + redirUrl + " !")
    if (window.confirm('Ok to Confirm, Cancel to Stay here'))
   {
   window.open('https://www.google.com', '_blank');
   };
  }
  
  return (
    <div style={{marginBottom:'50px'}}>
     


        {apps.map(app => (
          <>
          <Link to ={'./' + app.page} target="_blank" style = {{textDecoration:"none"}}>
          <div className="Software-Item">
          <div className="Item-Header" key={app.id}>
            {app.title}{' '}
          </div>
          <div className="Box-Padding">
   
          <span key={app.id}>{app.synopsis}{' ... '}</span>
          </div>
         

          {/*
          <span className="buttons"><button className="button1"><Link to={'./' + app.page} target="_blank" style={{textDecoration: 'none', color: '#1098F7'}}>Get</Link></button> </span>
        */}

         <div style={{textAlign: 'right', padding: '5%', fontSize: '16px', color: '#a19580'}} key={app.id}>{app.date}{'  '}</div>
         
         
          </div>
          </Link>
        </>
        
      
        ))}
      
     
    </div>
  );
}

export default Blog;